const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '`': '&#96;'
};

Object.defineProperties(String.prototype, {
    hashCode: {
        get() {
            let hash = 0;
            if(this.length === 0) return hash;
            for(let i = 0; i < this.length; i++) {
                const chr = this.charCodeAt(i);
                hash = ((hash << 5) - hash) + chr;
                hash |= 0; // Convert to 32bit integer
            }
            return hash;
        }
    },
    titleize: {
        get() {
            return this.replace(/(?:^|\s)(\S)/g, m => m.toUpperCase());
        }
    },
    toHTMLEntities: {
        get() {
            return this.replace(/[&<>"'`]/g, m => entityMap[m]);
        }
    },
    unpack: {
        value(chars) {
            let decoded = 0;
            for(let i = 0, n = this.length; i < n; i++) decoded = decoded * 62 + chars.indexOf(this[i]);
            return decoded;
        }
    },
    unpack62: {
        get() {
            return this.unpack('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz');
        }
    },
    reverse: {
        get() {
            return [...this].reverse().join("");
        }
    }

});